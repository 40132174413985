import { useState, useCallback, useSyncExternalStore, useEffect, useInsertionEffect } from 'react';
import { useLeafletMaps } from './useLeafletMaps.js';
import MfTiles from './MfTiles.js';

const mapInfoLookup = {
  1: {
    mapDivId: 'mf-map1',
    mapSelectorFunc: state => state.maps.map1,
    extStoreSubscribeFunc: null,
    extStoreGetSnapshotFunc: null,
  },
  2: {
    mapDivId: 'mf-map2',
    mapSelectorFunc: state => state.maps.map2,
    extStoreSubscribeFunc: null,
    extStoreGetSnapshotFunc: null,
  },
};

// track subscribers to each separate map
const mapStateLookup = {
  1: { mapObj: null, subscriberCallbacks: new Set() },
  2: { mapObj: null, subscriberCallbacks: new Set() },
};

const genExtStoreSubscribe = mapNum => callback => {
  const mapState = mapStateLookup[mapNum];
  mapState.subscriberCallbacks.add(callback);

  // return my unsubscribe callback
  return () => mapStateLookup[mapNum].subscriberCallbacks.delete(callback);
};

const genExtStoreGetSnapshot = (mapNum, leafletMaps) => () => {
  if (!leafletMaps) {
    return null;
  }

  let { mapObj: map } = mapStateLookup[mapNum];
  if (!map) {
    const thisMapOptions = {
      center: [37.93, -92.9],
      zoom: 3.5,
    };
    const { mapDivId } = mapInfoLookup[mapNum];
    map = leafletMaps.map(mapDivId, thisMapOptions);

    const mfTiles = new MfTiles(map);

    map._mfMetadata = {
      mfTiles,
    };

    mapStateLookup[mapNum].mapObj = map;
  }

  return map;
};

mapInfoLookup[1].extStoreSubscribeFunc = genExtStoreSubscribe(1);
mapInfoLookup[1].extStoreGetSnapshotFunc = genExtStoreGetSnapshot(1);
mapInfoLookup[2].extStoreSubscribeFunc = genExtStoreSubscribe(2);
mapInfoLookup[2].extStoreGetSnapshotFunc = genExtStoreGetSnapshot(2);

export const bringMapDivForward = (mapDivId, newParentId) => {
  const mapDiv = document.getElementById(mapDivId);
  const newParentDiv = document.getElementById(newParentId);
  if (newParentDiv) {
    newParentDiv.appendChild(mapDiv);
  }
};

export const pushMapDivBack = mapDivId => {
  const mapDiv = document.getElementById(mapDivId);
  const mapManagerDiv = document.getElementById('mf-mgr');
  mapManagerDiv?.appendChild(mapDiv);
};

export const useMap = ({ mapNum, parentDivId, onAdded, onRemove }) => {
  const mapInfo = mapInfoLookup[mapNum];
  const leafletMaps = useLeafletMaps();
  const [isMapDivRelocated, setIsMapDivRelocated] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const extStoreGetSnapshotFunc = useCallback(genExtStoreGetSnapshot(mapNum, leafletMaps), [
    mapNum,
    leafletMaps,
  ]);

  const thisMap = useSyncExternalStore(mapInfo.extStoreSubscribeFunc, extStoreGetSnapshotFunc);

  useEffect(() => {
    // AFTER this component is added to the DOM...
    if (leafletMaps && thisMap && !isMapDivRelocated) {
      const { mapDivId } = mapInfoLookup[mapNum];
      bringMapDivForward(mapDivId, parentDivId);
      thisMap.invalidateSize(false);
      thisMap.zoomControl.setPosition('bottomright');
      setIsMapDivRelocated(true);

      if (onAdded) {
        onAdded({ mapSystem: leafletMaps, mapNum, parentDivId, map: thisMap });
      }
    }

    // AFTER removal from dom: no cleanup needed
    return /* () => setIsMapDivRelocated(false) */;
  }, [leafletMaps, mapNum, parentDivId, thisMap, isMapDivRelocated, onAdded]);

  useInsertionEffect(() => {
    // BEFORE this component is added to the DOM: no action
    /* no-op */ 0;

    // BEFORE removal from the DOM: do cleanups
    // !!BAD "After every re-render with changed dependencies, React will first
    // run the cleanup function (if you provided it) with the old values, and then
    // run your setup function with the new values."
    return () => {
      if (isMapDivRelocated) {
        if (onRemove) {
          onRemove({ mapSystem: leafletMaps, mapNum, parentDivId, map: thisMap });
        }

        const { mapDivId } = mapInfoLookup[mapNum];
        pushMapDivBack(mapDivId);
        // setIsMapDivRelocated(false);  -- not allowed in useInsertionEffect()
      }
    };
  }, [leafletMaps, mapNum, parentDivId, thisMap, isMapDivRelocated, onRemove]);

  return thisMap;
};
