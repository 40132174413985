import { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, Button, Menu, MenuItem } from '@blueprintjs/core';
import { PageContext } from './PageContext';

// TODO: Fragile!! Currently we depend on 'menuText' fields below
// exactly matching '<PageContext.Provider value="****">'
// for each of the functional pages.
// Also see use of <MfCanonical/>
const pagesList = [
  {
    pathSegment: 'outlines',
    menuText: 'Compare Outlines',
  },
  {
    pathSegment: 'w2w',
    menuText: 'Wall to Wall',
  },
  {
    pathSegment: 'isoll',
    menuText: 'Iso-Lat/Lon',
  },
];

const pathSegmentLookup = new Map(pagesList.map(elem => [elem.menuText, elem.pathSegment]));

const lookupNavLink = mouseEvent => {
  const menuText = mouseEvent?.target?.firstChild?.data;
  const pathSegment = pathSegmentLookup.get(menuText);
  return pathSegment ? `../${pathSegment}` : '.';
};

function PageChooserMenu() {
  const pageName = useContext(PageContext);
  const navigate = useNavigate();

  const handleMenuItemSelection = useCallback(
    mouseEvent => {
      const navLinkStr = lookupNavLink(mouseEvent);
      navigate(navLinkStr);
    },
    [navigate]
  );

  return (
    <Menu>
      {pagesList.map(pageInfo => (
        <MenuItem
          key={pageInfo.pathSegment}
          text={pageInfo.menuText}
          roleStructure="listoption"
          selected={pageInfo.menuText === pageName}
          onClick={handleMenuItemSelection}
        />
      ))}
    </Menu>
  );
}

export function PageChooser() {
  return (
    <Popover content={<PageChooserMenu />} placement="bottom">
      <Button className="mf-page-chooser" endIcon="caret-down" fill={false} size="small" />
    </Popover>
  );
}
