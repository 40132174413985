import { createContext } from 'react';
import L from 'leaflet';

export const OutlinesContext = createContext({ map1: null, map2: null });

// export const LAYER_ID = 'move-outlines'; // TODO: remove?

export const OUTLINES_INSTRUCTIONS_DIALOG_ID = 'outlinesInstructionsDialog';
export const OUTLINES_SAVE_ASK_DIALOG_ID = 'outlinesDialogSaveAsk';
export const OUTLINES_SITE_INFO_DIALOG_ID = 'outlinesSiteInfoDialog';

// export const setOutlinesLayerGroup = (map, outlinesLayerGroup) =>
//   (map._mfMetadata.outlinesLayerGroup = outlinesLayerGroup);

export const getMfTiles = map => map?._mfMetadata?.mfTiles;
export const getOutlinesLayerGroup = map => map?._mfMetadata?.outlinesLayerGroup;

// XXXTODO: separation of concerns: OutlineList should do all maintenance
// of add/remove Layers within a LayerGroup
export const clearOutlinesLayerGroup = map => {
  const outlinesLayerGroup = map._mfMetadata?.outlinesLayerGroup;
  outlinesLayerGroup?.clearLayers();
};

export const getOutlinesMarkStartPushpin = map => map?._mfMetadata?.markStartPushpin;

// export const removeAllPolylinesFromBingLayer = bingMapsLayer => {
//   if (bingMapsLayer instanceof Microsoft.Maps.Layer) {
//     const primitivesList = bingMapsLayer.getPrimitives();
//     const polylineList = primitivesList.filter(prim => prim instanceof Microsoft.Maps.Polyline);
//     polylineList.forEach(polyline => bingMapsLayer.remove(polyline));
//   }
// };

export const genMap2Polylines = (pointLists1, outlines1Center, outlines2Center) => {
  const convertPoint = refPoint => {
    // get offset of reference point from center
    const ref_dx = refPoint.lng - outlines1Center.lng;
    const ref_dy = refPoint.lat - outlines1Center.lat;

    // map2 latitude is easy
    const point2Lat = outlines2Center.lat + ref_dy;

    // latitude radii at reference & comparison points (map1 & map2)
    const ref_latR = Math.cos(refPoint.lat * (Math.PI / 180));
    const cmp_latR = Math.cos(point2Lat * (Math.PI / 180));

    // adjust X for latitude circumference difference
    const point2Lng = outlines2Center.lng + (ref_dx * ref_latR) / cmp_latR;

    return L.latLng(point2Lat, point2Lng);
  };

  const polylineList2 = pointLists1.map(refPointList => {
    const polyline2Locations = refPointList.map(convertPoint);
    return L.polyline(polyline2Locations);
  });
  return polylineList2;
};

export const removeAllPolylinesFromLayerGroup = layerGroup => layerGroup.clearLayers();
