import { useState, useCallback, useEffect } from 'react';
import { Popover, Button } from '@blueprintjs/core';

function MfCopyableLink({ url }) {
  const [popoverContent, setPopoverContent] = useState(null);

  const handleClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(url);
      setPopoverContent('Link copied to clipboard!');
    } catch (error) {
      // console.error(error.message);
      setPopoverContent(`Copy to clipboard failed: "${error.message}"`);
    }

    setTimeout(() => setPopoverContent(null), 4000);
  }, [url, setPopoverContent]);

  useEffect(() => {
    // when added to DOM: no action
    0;

    // when removed from DOM: hide popover
    return () => setPopoverContent(null);
  }, [setPopoverContent]);

  return (
    <p className="mf-copyable-link">
      <span className="mf-persist-link">{url}</span>
      <Popover
        popoverClassName="mf-copy-result-popover"
        content={popoverContent}
        disabled={!popoverContent}
        isOpen={Boolean(popoverContent)}
      >
        <Button
          icon="duplicate"
          variant="minimal"
          className="mf-copy-link-icon"
          title="Copy link to clipboard"
          onClick={handleClick}
        />
      </Popover>
    </p>
  );
}

export default MfCopyableLink;
