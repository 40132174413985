import MfDialog from './MfDialog';
import { LeftOrTop, RightOrBottom } from './SideBySide';
import { OUTLINES_INSTRUCTIONS_DIALOG_ID } from './outlinesPageHelpers';

function OutlinesInfoDialog() {
  return (
    <MfDialog
      dialogId={OUTLINES_INSTRUCTIONS_DIALOG_ID}
      className="mf-outlines-info-dialog"
      icon="help"
      title="How to use MAPfrappe"
    >
      <p>
        This site shows you the relative sizes of different things in the world: cities, provinces,
        parks, buildings, countries, etc.
      </p>
      <h2 className="mfCompactHeader">How to compare geo sizes&hellip;</h2>
      <h2 className="mfCompactHeader">
        <i>First,</i> in the <LeftOrTop /> map:
      </h2>
      <ol className="mfUsageList mfCompactHeader">
        <li>
          Navigate to a place of interest.{' '}
          <span className="mfSearchBoxAside">Use the map’s search box if needed.</span>
        </li>
        <li>Click the map a few times to make an outline.</li>
      </ol>
      <h2 className="mfCompactHeader">
        <i>Next,</i> in the <RightOrBottom /> map:
      </h2>
      <ol className="mfUsageList mfCompactHeader" start="3">
        <li>
          Navigate to a different place of interest.{' '}
          <span className="mfSearchBoxAside">Again, use the search box if needed.</span>
        </li>
        <li>Move the map. Your outline floats over the map at the proper scale.</li>
      </ol>
    </MfDialog>
  );
}

export default OutlinesInfoDialog;
