import { Section } from '@blueprintjs/core';

const myCollapseProps = { defaultIsOpen: false };

const MfInfoSection = ({ title, children }) => (
  <Section
    className="mf-info-section"
    title={title}
    collapsible={true}
    collapseProps={myCollapseProps}
    compact={true}
  >
    <div className="mf-info-section-body bp5-running-text">{children}</div>
  </Section>
);

export default MfInfoSection;
