import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isBingMapsLoaded: false,
  map1DivId: null,
  map1: null,
  map2DivId: null,
  map2: null,
};

const mapsSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    mapsBingMapsHasLoaded(state) {
      state.isBingMapsLoaded = true;
    },
    mapsAssignMap1(state, action) {
      const { payload } = action;
      state.map1DivId = payload.map1DivId;
      state.map1 = payload.map1;
    },
    mapsAssignMapN(state, action) {
      const { payload } = action;
      if (payload.mapNum === 1) {
        state.map1DivId = payload.mapDivId;
        state.map1 = payload.mapObj;
      } else if (payload.mapNum === 2) {
        state.map2DivId = payload.mapDivId;
        state.map2 = payload.mapObj;
      } else {
        console.log(`Bogus map number ${payload.mapNum} in mapsAssignMapN().`);
      }
    },
  },
});

// export actions
export const { mapsBingMapsHasLoaded, mapsAssignMap1, mapsAssignMapN } = mapsSlice.actions;

export default mapsSlice.reducer;
