import { useSyncExternalStore } from 'react';
import L from 'leaflet';
import 'leaflet-providers'; // extends Leaflet; import in just this one place

import 'leaflet/dist/leaflet.css';

// track subscribers to useLeafletMaps
const g_subscriberCallbacks = new Set();

const extStoreSubscribe = callback => {
  g_subscriberCallbacks.add(callback);
  return () => g_subscriberCallbacks.delete(callback);
};

const extStoreGetSnapshot = () => {
  return typeof L === 'undefined' ? undefined : L;
};

export const notifyLeafletMapsChanged = () => {
  // if (!g_BingMaps && Microsoft?.Maps) {
  //   g_BingMaps = Microsoft?.Maps;
  //   for (const callback of g_subscriberCallbacks) {
  //     callback();
  //   }
  // }
};

export const useLeafletMaps = () => {
  const currentGlobalL = useSyncExternalStore(extStoreSubscribe, extStoreGetSnapshot);
  return currentGlobalL;
};
