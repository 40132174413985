import { useState, useCallback } from 'react';

const UNKNOWN_FAILURE_STATUS = 499;
const UNKNOWN_FAILURE_INFO = { status: UNKNOWN_FAILURE_STATUS, ok: false };
const UNKNOWN_FAILURE_RESPONSE = new Response(null, UNKNOWN_FAILURE_INFO);

class SearchEntry {
  static entryType = {
    INFO: 'INFO',
    RESULT: 'RESULT',
  };
  static TEXT_ITALIC = 'TEXT_ITALIC';

  constructor(type, text, data) {
    this.type = type;
    this.text = text;
    this.data = data;
  }
}

// abbreviations
const ENTRY_TYPE = SearchEntry.entryType;
const TEXT_ITALIC = SearchEntry.TEXT_ITALIC;

const SEARCH_ENTRY_TRY_AGAIN_LATER = new SearchEntry(ENTRY_TYPE.INFO, 'Please try again later.');

const SEARCH_CONTENT_PRESS_ENTER = [
  new SearchEntry(ENTRY_TYPE.INFO, 'Press Enter when search string is complete', TEXT_ITALIC),
];

const SEARCH_CONTENT_LOOKUP_REJECTED = [
  new SearchEntry(ENTRY_TYPE.INFO, 'Location lookup was rejected.', TEXT_ITALIC),
  new SearchEntry(
    ENTRY_TYPE.INFO,
    'Most likely, you and other MAPfrappe users are sending too many MAPfrappe requests right now. ' +
      'Or the location service may be having technical problems.'
  ),
  SEARCH_ENTRY_TRY_AGAIN_LATER,
];

const SEARCH_CONTENT_TOO_MANY = [
  new SearchEntry(ENTRY_TYPE.INFO, 'Too many MAPfrappe requests per second.', TEXT_ITALIC),
  new SearchEntry(
    ENTRY_TYPE.INFO,
    'You and other MAPfrappe users are sending too many MAPfrappe requests right now.'
  ),
  SEARCH_ENTRY_TRY_AGAIN_LATER,
];

const SEARCH_CONTENT_OVERLOADED = [
  new SearchEntry(ENTRY_TYPE.INFO, 'Location search service is overloaded.'),
  SEARCH_ENTRY_TRY_AGAIN_LATER,
];

const SEARCH_CONTENT_UNEXPECTED_RESULT = [
  new SearchEntry(ENTRY_TYPE.INFO, 'Unexpected result from search service.'),
  SEARCH_ENTRY_TRY_AGAIN_LATER,
];

// The boundingbox result from geocode.maps.co is an array of strings
// like: ['40.7557728', '40.7558728', '-73.9788465', '-73.9787465'].
// NOTE: that's [latMin, latMax, lonMin, lonMax]
// Convert that to an array-of-arrays that Leaflet accepts:
// [[40.7557728, -73.9788465], [40.7558728, -73.9787465]]
// NOTE: [[latMin, lonMin], [latMax, lonMax]]
const BAD_LEAFLET_BOUNDS = [
  [0, 0],
  [0, 0],
];
const toLeafletBounds = boundingbox => {
  let result = [
    [parseFloat(boundingbox[0]), parseFloat(boundingbox[2])],
    [parseFloat(boundingbox[1]), parseFloat(boundingbox[3])],
  ];

  if (
    Number.isNaN(result[0][0]) ||
    Number.isNaN(result[0][1]) ||
    Number.isNaN(result[1][0]) ||
    Number.isNaN(result[1][1])
  ) {
    result = BAD_LEAFLET_BOUNDS;
  }

  return result;
};

export function SearchBox({ map }) {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchContent, setSearchContent] = useState(SEARCH_CONTENT_PRESS_ENTER);

  const handleFocusChange = useCallback(e => {
    setIsFocused(e.currentTarget === document.activeElement);
  }, []);

  const handleInputChange = useCallback(e => {
    setInputValue(e.target.value);
  }, []);

  const handleKeyDown = useCallback(
    async e => {
      const { keyCode, key } = e;
      const isDuringComposition = keyCode === 229;
      if (!isDuringComposition && key === 'Enter') {
        const searchEntry = new SearchEntry(
          ENTRY_TYPE.INFO,
          `Searching for '${inputValue}'…`,
          TEXT_ITALIC
        );
        setSearchContent([searchEntry]);

        // When 'geocode.maps.co' sends a successful response, the response header contains
        // "Access-Control-Allow-Origin:*", which allows CORS to be successful. However, when
        // the site sends a 429 response (too many requests), then the response header does
        // not have this attribute, and the browser fails the fetch. I assume this occurs for
        // other failure codes as well.  See: https://stackoverflow.com/questions/43317967
        let fetchedResponse = null;
        try {
          // NOTE: Parcel does a wholesale substitution of 'process.env.MAPS_CO_API_KEY'
          // and 'process' is not available in the debugger
          // eslint-disable-next-line no-undef
          const urlQuery = `search?q=${inputValue}&api_key=${process.env.MAPS_CO_API_KEY}`;
          const searchUrl = new URL(urlQuery, 'https://geocode.maps.co');
          fetchedResponse = await fetch(searchUrl);
        } catch (rejectReason) {
          fetchedResponse = UNKNOWN_FAILURE_RESPONSE;
        }

        let searchContent;
        if (!fetchedResponse.ok) {
          const { status } = fetchedResponse;
          if (status === UNKNOWN_FAILURE_STATUS) {
            searchContent = SEARCH_CONTENT_LOOKUP_REJECTED;
          } else if (status === 429) {
            searchContent = SEARCH_CONTENT_TOO_MANY;
          } else if (status === 503) {
            searchContent = SEARCH_CONTENT_OVERLOADED;
          } else {
            searchContent = [
              new SearchEntry(ENTRY_TYPE.INFO, `Unknown error '${status}' from location service.`),
              SEARCH_ENTRY_TRY_AGAIN_LATER,
            ];
          }
        } else {
          const fetchedResult = await fetchedResponse.json();

          if (!Array.isArray(fetchedResult)) {
            searchContent = SEARCH_CONTENT_UNEXPECTED_RESULT;
          } else if (fetchedResult.length === 0) {
            searchContent = [
              new SearchEntry(ENTRY_TYPE.INFO, `No results for search term '${inputValue}'.`),
              new SearchEntry(ENTRY_TYPE.INFO, 'Please try a different location search.'),
            ];
          } else {
            // KKT 14-Feb-2025: I'm seeing duplicates in some results.
            // E.g. for 'Waterloo'.  Let's filter out duplicates.
            const knownNames = new Set();
            const filteredResult = fetchedResult.filter(candidate => {
              const isKnown = knownNames.has(candidate.display_name);
              knownNames.add(candidate.display_name);
              return !isKnown;
            });

            searchContent = filteredResult.map(
              candidate => new SearchEntry(ENTRY_TYPE.RESULT, candidate.display_name, candidate)
            );
          }
        }

        setSearchContent(searchContent);
      }
    },
    [inputValue]
  );

  const isAnyInput = inputValue.trim().length > 0;
  const isAnyContent = searchContent.length > 0;
  const isShowingMatches = isAnyContent && searchContent[0].type === ENTRY_TYPE.RESULT;
  const isDisplayed = isShowingMatches || (isFocused && isAnyInput && isAnyContent);

  return (
    <div className="mf-search-container">
      <input
        type="text"
        placeholder="Enter search text"
        value={inputValue}
        spellCheck="false"
        autoCorrect="off"
        onFocus={handleFocusChange}
        onBlur={handleFocusChange}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      {isDisplayed && (
        <div className="mf-search-status">
          {searchContent.map(entry => {
            let element = null;
            if (entry.type === ENTRY_TYPE.INFO) {
              const className = entry.data === TEXT_ITALIC ? 'mf-italic' : '';
              element = (
                <p key={entry.text} className={className}>
                  {entry.text}
                </p>
              );
            } else if (entry.type === ENTRY_TYPE.RESULT) {
              const { text, data } = entry;

              const handleSelection = () => {
                const { boundingbox } = data;
                const leafletBounds = toLeafletBounds(boundingbox);
                if (leafletBounds === BAD_LEAFLET_BOUNDS) {
                  setSearchContent(SEARCH_CONTENT_UNEXPECTED_RESULT);
                } else {
                  map.fitBounds(leafletBounds);
                  setInputValue(text);
                  setSearchContent(SEARCH_CONTENT_PRESS_ENTER);
                }
              };

              element = (
                <p key={text} className="search-matched" onClick={handleSelection}>
                  {text}
                </p>
              );
            }
            return element;
          })}
        </div>
      )}
    </div>
  );
}
