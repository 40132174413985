import { createSlice } from '@reduxjs/toolkit';

export const HIDE_DIALOG_ID = '';

const initialState = {
  bodyWidth: null,
  bodyHeight: null,
  dialogId: HIDE_DIALOG_ID,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    globalUpdateBodyDimensions(state, action) {
      const { width, height } = action.payload;
      state.bodyWidth = width;
      state.bodyHeight = height;
    },
    globalUpdateDialogId(state, action) {
      state.dialogId = action.payload;
    },
    globalHideDialog(state) {
      state.dialogId = HIDE_DIALOG_ID;
    },
  },
});

export function getBodyDimensions(state) {
  const { bodyWidth, bodyHeight } = state.global;
  return {
    bodyWidth,
    bodyHeight,
  };
}

export function getBodySizeRatio(state) {
  const { bodyWidth, bodyHeight } = state.global;
  const ratio = bodyWidth / bodyHeight;
  return Number.isFinite(ratio) ? ratio : 1.0;
}

export function getDialogId(state) {
  const { dialogId } = state.global;
  return dialogId;
}

// export actions
export const { globalUpdateBodyDimensions, globalUpdateDialogId, globalHideDialog } =
  globalSlice.actions;

export default globalSlice.reducer;
