import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getBodySizeRatio } from './globalSlice';
import { SideBySideChild } from './SideBySideChild';

const cutoffRatio = 1.2;
const NO_OP_FUNC = () => {};

export const getIsHorizFromBodySizeRatio = bodySizeRatio => bodySizeRatio > cutoffRatio;

export const LeftOrTop = ({ className, ...spreadProps }) => {
  const bodySizeRatio = useSelector(getBodySizeRatio);
  const isHoriz = getIsHorizFromBodySizeRatio(bodySizeRatio);
  return (
    <span className={classNames('sxs-pane-name mfFirstMapName', className)} {...spreadProps}>
      {isHoriz ? 'left' : 'top'}
    </span>
  );
};

export const RightOrBottom = ({ className, ...spreadProps }) => {
  const bodySizeRatio = useSelector(getBodySizeRatio);
  const isHoriz = getIsHorizFromBodySizeRatio(bodySizeRatio);
  return (
    <span className={classNames('sxs-pane-name mfSecondMapName', className)} {...spreadProps}>
      {isHoriz ? 'right' : 'bottom'}
    </span>
  );
};

export function SideBySide({ repositionCallback, children }) {
  const bodySizeRatio = useSelector(getBodySizeRatio);
  const vertOrHorizClassName = getIsHorizFromBodySizeRatio(bodySizeRatio) ? 'mf-horiz' : 'mf-vert';

  // This effect is a no-op for the real effect, and returns only a cleanup function
  useEffect(() => repositionCallback || NO_OP_FUNC, [repositionCallback, vertOrHorizClassName]);

  return (
    <div id="mf-sxs-container" className={vertOrHorizClassName}>
      <SideBySideChild childIdx={0}>{children[0]}</SideBySideChild>
      <SideBySideChild childIdx={1}>{children[1]}</SideBySideChild>
    </div>
  );
}
