import L from 'leaflet';

// Historically, MAPfrappe's database storage has used different map type names
// *versus* Google Maps type names:
//       Google Map Type Id    MAPfrappe database
//          'roadmap'             'normal'     -- usual street map
//          'satellite'           'satellite'  -- satellite map without labels
//          'hybrid'              'hybrid'     -- satellite map with labels
//          'terrain'             'physical'   -- road map with terrain indications
// The above possibly reflects very-old naming from Google Maps.
// See:
// https://developers.google.com/maps/documentation/javascript/reference/map#MapTypeId

class MfTiles {
  static ROADMAP = 'roadmap';
  static SATELLITE = 'satellite';
  static UNKNOWN = 'unknown';
  static LEGIT_LAYER_NAMES = new Set(MfTiles.ROADMAP, MfTiles.SATELLITE);
  static LOOKUP_LAYER_NAME_FROM_DB_MAP_TYPE = new Map([
    ['normal', MfTiles.ROADMAP],
    ['satellite', MfTiles.SATELLITE],
    ['hybrid', MfTiles.SATELLITE],
    ['physical', MfTiles.ROADMAP],
  ]);
  static LOOKUP_DB_MAP_TYPE_FROM_LAYER_NAME = new Map([
    [MfTiles.ROADMAP, 'normal'],
    [MfTiles.SATELLITE, 'satellite'],
  ]);

  static getTileLayerNameFromDbMapType = dbMapType =>
    MfTiles.LOOKUP_LAYER_NAME_FROM_DB_MAP_TYPE.get(dbMapType) || MfTiles.UNKNOWN;

  constructor(map, defaultTileLayerName) {
    // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   maxZoom: 19,
    //   attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    // }).addTo(mapObj);

    // create tile layers
    this.map = map;
    this.streetTileLayer = L.tileLayer.provider('OpenStreetMap.Mapnik');
    this.satelliteTileLayer = L.tileLayer.provider('Esri.WorldImagery');

    this.layerLookupFromName = new Map([
      [MfTiles.ROADMAP, this.streetTileLayer],
      [MfTiles.SATELLITE, this.satelliteTileLayer],
    ]);

    // base-maps to set up Leaflet layers control
    const baseMaps = {
      Streets: this.streetTileLayer,
      Satellite: this.satelliteTileLayer,
    };

    // enable  default layer
    if (defaultTileLayerName === MfTiles.SATELLITE) {
      map.addLayer(this.satelliteTileLayer);
    } else {
      map.addLayer(this.streetTileLayer);
    }

    this.layerControl = L.control.layers(baseMaps).addTo(map);
  }

  getLayerControl() {
    return this.layerControl;
  }

  getCurrentTileLayerName() {
    const { map } = this;
    let result = MfTiles.UNKNOWN;
    if (map.hasLayer(this.streetTileLayer)) {
      result = MfTiles.ROADMAP;
    } else if (map.hasLayer(this.satelliteTileLayer)) {
      result = MfTiles.SATELLITE;
    }
    return result;
  }

  getCurrentDbMapType() {
    const tileLayerName = this.getCurrentTileLayerName();
    return (
      MfTiles.LOOKUP_DB_MAP_TYPE_FROM_LAYER_NAME.get(tileLayerName) ||
      MfTiles.LOOKUP_DB_MAP_TYPE_FROM_LAYER_NAME.get(MfTiles.ROADMAP)
    );
  }

  getCurrentTileLayer() {
    const { map, streetTileLayer, satelliteTileLayer } = this;
    let result;
    if (map.hasLayer(streetTileLayer)) {
      result = streetTileLayer;
    } else if (map.hasLayer(satelliteTileLayer)) {
      result = satelliteTileLayer;
    }
    return result;
  }

  setCurrentTileLayer(tileLayerName) {
    const { map, layerLookupFromName } = this;
    const requestedTileLayer = layerLookupFromName.get(tileLayerName);
    if (requestedTileLayer && !map.hasLayer(requestedTileLayer)) {
      const currentTileLayer = this.getCurrentTileLayer();
      if (currentTileLayer) {
        map.removeLayer(currentTileLayer);
      }
      map.addLayer(requestedTileLayer);
    }
  }
}

export default MfTiles;
