import L from 'leaflet';
import { isPathInitializerItem } from './helpers';

const polylineColorOpacity = 0.5;
// eslint-disable-next-line no-unused-vars
const polylineColorComponents = {
  // '#2222ff'
  r: 0x22,
  g: 0x22,
  b: 0xff,
  a: polylineColorOpacity,
};
const polylineOptions = {
  color: '#2222ff',
  opacity: polylineColorOpacity,
  weight: 3,
  interactive: false,
};

function getAllPolylineOptions() {
  return polylineOptions;
}

class Outline {
  static isValidInitContent(initContent) {
    const isUndefinedContent = typeof initContent === 'undefined';
    const isOutline = !isUndefinedContent && initContent instanceof Outline;
    const isArrayContent = !isUndefinedContent && Array.isArray(initContent);
    const isLocationArray = isArrayContent && isPathInitializerItem(initContent[0]);
    const isPolylineContent = !isUndefinedContent && initContent instanceof L.Polyline;

    return isUndefinedContent || isOutline || isLocationArray || isPolylineContent;
  }

  constructor(initContent) {
    const isUndefinedContent = typeof initContent === 'undefined';
    const isOutline = !isUndefinedContent && initContent instanceof Outline;
    const isArrayContent = !isUndefinedContent && Array.isArray(initContent);
    const isLocationArray = isArrayContent && isPathInitializerItem(initContent[0]);
    const isPolylineContent = !isUndefinedContent && initContent instanceof L.Polyline;

    this.pointList = [];
    this.polyline = null;

    if (isUndefinedContent) {
      this.polyline = L.polyline(this.pointList, getAllPolylineOptions());
    } else if (isOutline) {
      this.pointList = [...initContent.polyline.getLatLngs()];
      this.polyline = L.polyline(this.pointList, getAllPolylineOptions());
    } else if (isLocationArray || isPolylineContent) {
      this.pointList = isLocationArray ? [...initContent] : [...initContent.getLatLngs()];
      this.polyline = L.polyline(this.pointList, getAllPolylineOptions());
    } else {
      console.error('Bogus Outline() constructor', typeof initContent);
    }
  }

  getNumPoints() {
    return this.pointList.length;
  }

  getPointList() {
    return this.pointList;
  }

  // getClonedPointList() {
  //   return [...this.pointList];
  // }

  getBounds() {
    return this.polyline.getBounds();
  }

  getLayer() {
    return this.polyline;
  }

  addPoint(newPoint) {
    const newPointCopy = L.latLng({ lat: newPoint.lat, lng: newPoint.lng });
    this.pointList.push(newPointCopy);
    this.polyline.addLatLng(newPointCopy);

    // const numPoints = this.pointList.length;
    // if (numPoints === 2) {
    //   this.polyline = new Microsoft.Maps.Polyline(this.pointList, getAllPolylineOptions());
    //   this.layer.add(this.polyline);
    // } else if (numPoints > 2) {
    //   this.polyline.setLocations(this.pointList);
    // }
  }

  removeLastPoint() {
    const removedLatLng = this.pointList.pop();
    this.polyline.setLatLngs(this.pointList);
    return removedLatLng;
  }

  getProjectedPoints(leafletMap) {
    const latLngArray = this.polyline.getLatLngs() || [];
    return latLngArray.map(latLng => {
      return leafletMap.latLngToContainerPoint(latLng);
    });
  }

  getProjectedPointsSeq(leafletMap) {
    const latLngArray = this.polyline.getLatLngs() || [];
    return latLngArray.map(latLng => {
      const containerPoint = leafletMap.latLngToContainerPoint(latLng);
      return { x: containerPoint.x, y: containerPoint.y };
    });
  }

  genXml() {
    const { pointList } = this;
    if (pointList.length < 2) {
      return '';
    }

    const lastVtx = pointList[pointList.length - 1];
    const isClosedNum = pointList[0].equals(lastVtx) ? 1 : 0;
    const xmlTextStart = `<polyline topo='${isClosedNum}'>\n`;
    const xmlTextBody = pointList.reduce(
      (accum, latLng) => accum + `<vtx lat='${latLng.lat}' lng='${latLng.lng}' />\n`,
      ''
    );

    return xmlTextStart + xmlTextBody + '</polyline>\n';
  }
}

export default Outline;
