import L from 'leaflet';

// NOTE: Parcel previously auto-added the following dev packages for XML parse/import
//   "@parcel/packager-xml": "^2.13.3",
//   "@parcel/transformer-xml": "^2.13.3",
// import sampleOutlineResponseText from 'bundle-text:./sampleResponseText2.xml';

export const isLatLng = item => item instanceof L.LatLng;

export const isLeafletPair = item =>
  Array.isArray(item) && item.length === 2 && Number.isFinite(item[0]) && Number.isFinite(item[1]);

export const isPathInitializerItem = item => isLatLng(item) || isLeafletPair(item);

export const getSearchParams = () => new URLSearchParams(location.search);

const domParser = new DOMParser();

const defaultFetchHeaders = new Headers();
defaultFetchHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

export const fetchPhpContent = async (phpName, body) => {
  let urlPath = `${location.origin}/${phpName}`;
  const options = {
    method: 'POST',
    headers: defaultFetchHeaders,
    body,
  };

  const response = await fetch(urlPath, options);

  let xmlDocument = null;
  if (response.ok) {
    const responseText = await response.text();
    xmlDocument = domParser.parseFromString(responseText, 'text/xml');
  }

  return xmlDocument;
};
