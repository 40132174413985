import { useRef, createContext } from 'react';

const SXS_CONTAINER0_ID = 'mf-wrapper-0'; // ?? never used?
const SXS_CONTAINER1_ID = 'mf-wrapper-1';
const CONTAINER_ID_LOOKUP = [SXS_CONTAINER0_ID, SXS_CONTAINER1_ID];

const UNKNOWN_DIMENSIONS = {};
export const SideBySideChildContext = createContext(UNKNOWN_DIMENSIONS);

export function SideBySideChild({ childIdx, children }) {
  const ref = useRef(null);

  const wrapperClassNames = `mf-sxs-pane mf-sxs-${childIdx}`;
  const width = ref.current?.clientWidth || 0;
  const height = ref.current?.clientHeight || 0;

  return (
    <div id={CONTAINER_ID_LOOKUP[childIdx]} className={wrapperClassNames} ref={ref}>
      <SideBySideChildContext.Provider value={{ width, height }}>
        {children || null}
      </SideBySideChildContext.Provider>
    </div>
  );
}
