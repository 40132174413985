import { Button } from '@blueprintjs/core';

export default function BannerOpsButton(buttonProps) {
  // OMIT: intent={Classes.INTENT_PRIMARY} -- removes button border
  // to make fully non-focusable:
  // https://stackoverflow.com/questions/9152096
  return (
    <Button
      className="mf-banner-ops-item"
      size="medium"
      variant="outlined"
      tabIndex="-1"
      active={false}
      {...buttonProps}
    />
  );
}
