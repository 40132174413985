import { BannerOps } from './BannerOps';
import OutlinesBannerOpsSave from './OutlinesBannerOpsSave';
import BannerOpsButtonSiteInfo from './BannerOpsButtonSiteInfo';
import { OUTLINES_SITE_INFO_DIALOG_ID } from './outlinesPageHelpers';
import { LeftOrTop, RightOrBottom } from './SideBySide';
import MfInfoSection from './MfInfoSection';
import MfNameInText from './MfNameInText';
import MfLinkInText from './MfLinkInText';

// image imports for Parcel...
import outlineClosedPng from './OutlineClosed.png';
import outlineOpenPng from './OutlineOpen.png';
import outlineCMultiPng from './OutlineMulti.png';

export function OutlinesBannerOps() {
  return (
    <BannerOps>
      <OutlinesBannerOpsSave />
      <BannerOpsButtonSiteInfo dialogId={OUTLINES_SITE_INFO_DIALOG_ID}>
        <MfInfoSection title="Basic information">
          <p>
            <MfNameInText /> shows you an outline of one part of the world overlaid on another part
            of the world.
          </p>
          <p>
            You draw an outline in the <LeftOrTop /> map, and that outline is overlaid over the{' '}
            <RightOrBottom /> map – at an appropriate size.
          </p>
          <p>
            For example, you can sketch an outline of California in the <LeftOrTop /> map; and you
            can see your outline overlaid over Italy in the other map. Your outline stays centered
            in the <RightOrBottom /> map, and at an appropriate size.
          </p>
          <p>Here&rsquo;s how you can use this page&hellip;</p>
          <p>
            <b>1)</b> Click on the <LeftOrTop /> map to draw an outline. Each click makes a new
            segment of the outline. Click on Close or End to finish one outline and start a new,
            disconnected outline. You can draw a closed outline{' '}
            <span className="mf-inline-graphic">
              (<img alt="ClosedOutlineSample" src={outlineClosedPng} width="9" height="11" />
              ),
            </span>{' '}
            or an open outline{' '}
            <span className="mf-inline-graphic">
              (<img alt="OpenOutlineSample" src={outlineOpenPng} width="9" height="11" />
              ),
            </span>{' '}
            or several outlines{' '}
            <span className="mf-inline-graphic">
              (<img alt="MultiOutlineSample" src={outlineCMultiPng} width="17" height="11" />
              ).
            </span>{' '}
            An outline can have as many points as you want. But if you use a large number of points,
            your browser may redraw the image slowly.
          </p>
          <p>
            <b>2)</b> As you draw your outline in the <LeftOrTop /> map, the outline appears at the
            center of the <RightOrBottom className="mfSecondMapName" /> map. You can drag and zoom
            the <RightOrBottom /> map to anywhere in the world, and the outline stays in the center
            of the map. The outline keeps the same real-world size as in the <LeftOrTop /> map, so
            it may become very large or very tiny in the <RightOrBottom /> map.
          </p>
          <p>
            You can use this page to answer questions like these: How big is Manhattan compared to
            my home town? How big is Texas compared to France? How big is the Golden Gate Bridge
            compared to my neighborhood?
          </p>
        </MfInfoSection>

        <MfInfoSection title="Shape distortion">
          <p>
            Your outline’s size or shape may change as you move around on the map. This distortion
            occurs for two different reasons.
          </p>
          <p>
            First, the underlying mapping system uses a{' '}
            <MfLinkInText rel="external" href="http://en.wikipedia.org/wiki/Mercator_projection">
              Mercator projection
            </MfLinkInText>{' '}
            for its maps. By design, this projection distorts distances at different latitudes.
            Distances are magnified at higher latitudes.
          </p>
          <p>
            Second, my calculation of comparison marker positions attempts to preserve true
            distances between markers. These calculations account for the fact that units of
            longitude have different lengths depending on latitude. The calculations reverse
            Mercator distortions of horizontal distances.
          </p>
        </MfInfoSection>

        <MfInfoSection title="Related sites">
          <p>If you like this page, you might also like some of these:</p>
          <ul>
            <li>
              <MfLinkInText
                title="Affiliated site"
                rel="external"
                href="http://mapfrappe.blogspot.com"
              >
                Drew&rsquo;s <MfNameInText /> index and blog.
              </MfLinkInText>{' '}
              Back around 2010-2012, a <MfNameInText /> fan created a publicly shared index of map
              comparisons. — Thanks Drew!
            </li>
            <li>
              <MfLinkInText title="The True Size" rel="external" href="http://thetruesize.com">
                The True Size.
              </MfLinkInText>{' '}
              Interact with outlines of selected countries and U.S. states -{' '}
              <strong>supports rotation</strong>.
            </li>
            <li>
              <MfLinkInText
                title="Distance-measuring web site"
                rel="external"
                href="http://www.gmap-pedometer.com"
              >
                Gmap Pedometer.
              </MfLinkInText>{' '}
              Draw a route or outline on a map, and the site tells you the length of the route. The
              site also lets you associate your route with a permanent URL.
            </li>
            <li>
              <MfLinkInText
                title="Area-measuring web site"
                rel="external"
                href="http://www.acme.com/planimeter"
              >
                ACME Planimeter.
              </MfLinkInText>{' '}
              Draw an outline on a map, and the site tells you the area of the enclosed region.
            </li>
            <li>
              <MfLinkInText
                title="Funny skit from SCTV"
                rel="external"
                href="http://www.youtube.com/watch?v=BXqKkYYALMU"
              >
                Old SCTV Skit.
              </MfLinkInText>{' '}
              <MfLinkInText
                rel="external"
                href="http://en.wikipedia.org/wiki/Second_City_Television"
              >
                SCTV
              </MfLinkInText>{' '}
              did this long before I did.
            </li>
          </ul>
        </MfInfoSection>

        {/* <MfInfoSection title="Future work">
          <p className="mfWorkHeader">User Interface Improvements</p>
          <ul>
            <li>Improved callout of incompatible browsers.</li>
            <li>Shrink search box for narrow maps.</li>
            <li>Fix control button highlight on some browsers.</li>
            <li>Nicer fail message for very small screens.</li>
          </ul>
          <p className="mfWorkHeader">Features and Bug Fixes</p>
          <ul>
            <li>
              Rotation of reference objects. <i>Most requested.</i>
            </li>
            <li>Experiment with more Android devices.</li>
            <li>Get Internet Explorer working again.</li>
            <li>Tabbed interface to switch between the two maps.</li>
            <li>Fix bugs at the International Date Line.</li>
            <li>Proper spherical geometry.</li>
            <li>Auto-navigate button when the user gets lost.</li>
            <li>User dragging of Comparison overlay.</li>
          </ul>
        </MfInfoSection> */}
      </BannerOpsButtonSiteInfo>
    </BannerOps>
  );
}
