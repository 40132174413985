import { useRef, useEffect } from 'react';
import store from './store';
import { globalUpdateBodyDimensions } from './globalSlice';

// size tracking suggested by https://stackoverflow.com/questions/72822172

const getDimensionsFromElement = elem => {
  const { offsetWidth, offsetHeight } = elem;
  return {
    width: offsetWidth,
    height: offsetHeight,
  };
};

export function BodyLayout({children}) {
  const ref = useRef(null);

  useEffect(() => {
    // function to handle page resize
    const updateBodyDimensions = () => {
      const dimensions = getDimensionsFromElement(ref.current);
      const action = globalUpdateBodyDimensions(dimensions);
      store.dispatch(action);
    };

    updateBodyDimensions();

    window.addEventListener('resize', updateBodyDimensions);

    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener('resize', updateBodyDimensions);
  }, []);

  return (
    <div ref={ref} className="mf-main">
      {children}
    </div>
  );
}
