import { useCallback, useEffect } from 'react';
import L from 'leaflet';
import store from './store';
import { assignOverlayOutlines, removeOverlayOutlines, assignIsSaveEnabled } from './outlinesSlice';
import { useMap } from './useMap';
import { fetchPhpContent } from './helpers';
import { PageContext } from './PageContext';
import MfCanonical from './MfCanonical';
import { BannerLayout } from './BannerLayout';
import { OutlinesBannerControls } from './OutlinesBannerControls';
import { OutlinesBannerOps } from './OutlinesBannerOps';
import { BodyLayout } from './BodyLayout';
import { SideBySide } from './SideBySide';
import { SearchBox } from './SearchBox';
import Outline from './Outline';
import OutlineList from './OutlineList';
import {
  OutlinesContext,
  getOutlinesLayerGroup,
  clearOutlinesLayerGroup,
  genMap2Polylines,
} from './outlinesPageHelpers';
import OutlinesInfoDialog from './OutlinesInfoDialog';
import { OutlinesMap2DraggingOverlay } from './OutlinesMap2DraggingOverlay';
import MfTiles from './MfTiles';

// eslint-disable-next-line import/no-unresolved
import mapMarkerSvgString from 'bundle-text:./pin-outline.svg'; // 'bundle-text:./mapMarker.svg';

const OUTLINES_MAP1_CONTAINER_ID = 'outlines-container-1';
const OUTLINES_MAP2_CONTAINER_ID = 'outlines-container-2';

const genOutlineFromXmlElement = polylineElement => {
  const topoStr = polylineElement.getAttribute('topo');
  const isClosed = topoStr === '1';

  const vtxElementCollection = polylineElement.getElementsByTagName('vtx');

  const outlineInitializer = [];
  for (let idx = 0; idx < vtxElementCollection.length; idx++) {
    const vtxElement = vtxElementCollection[idx];
    const pairStr = [vtxElement.getAttribute('lat'), vtxElement.getAttribute('lng')];
    const pairFloat = [parseFloat(pairStr[0]), parseFloat(pairStr[1])];
    if (!Number.isFinite(pairFloat[0]) || !Number.isFinite(pairFloat[1])) {
      outlineInitializer.splice(0, Infinity);
      break;
    }
    outlineInitializer.push(pairFloat);
  }

  if (isClosed && outlineInitializer.length > 2) {
    const zerothPair = outlineInitializer[0];
    outlineInitializer.push(zerothPair); // note same instance - is that okay?
  }

  return new Outline(outlineInitializer);
};

const getMapSettingsFromXmlElement = mapElement => {
  // these attribute names match the ones we expect in the <map/> XML element
  const mapSettings = {
    num: null, // 1 or 2
    lat: null,
    lng: null,
    zoom: null,
    type: null,
  };

  const keysList = Object.keys(mapSettings);
  keysList.forEach(attrName => {
    mapSettings[attrName] = mapElement.getAttribute(attrName);
  });

  return mapSettings;
};

const updateMap2Outlines = (map1, map2, latlng) => {
  const outlines1LayerGroup = getOutlinesLayerGroup(map1);
  const { _mfMetadata: metadata1 } = outlines1LayerGroup;
  const {
    outlineList: outline1List,
    markStartPushpin: markStartPushpin1,
    outlineBounds,
  } = metadata1;

  const outlines2LayerGroup = getOutlinesLayerGroup(map2);
  const { _mfMetadata: metadata2 } = outlines2LayerGroup;
  const { outlineList: outline2List } = metadata2;

  const isSaveEnabled = outline1List.isAnySegments();
  store.dispatch(assignIsSaveEnabled(isSaveEnabled));

  if (outline1List.length < 1) {
    outline2List.clear();
    return;
  }

  if (!outlineBounds || !latlng || !outlineBounds.contains(latlng)) {
    metadata1.outlineBounds = outline1List.getBounds();
  }

  const pointLists1 = outline1List.getPointLists();
  const outlines1Center = metadata1.outlineBounds.getCenter();
  const outlines2Center = map2.getCenter();
  const polylineList2 = genMap2Polylines(pointLists1, outlines1Center, outlines2Center);

  outline2List.init(polylineList2);

  // update "start of polyline" pushpin
  const lastOutline1 = outline1List.getLastOutline();
  const lastOutlineNumPoints = lastOutline1.getNumPoints();
  if (lastOutlineNumPoints === 1) {
    // map #1
    markStartPushpin1.setLatLng(lastOutline1.pointList[0]);
    markStartPushpin1.addTo(map1);

    // map #2 - TODO?: enable this ~ should move with polyline which changes often
    // const lastPolyline = polylineList2[polylineList2.length - 1];
    // const lastPolylineLocations = lastPolyline.getLocations();
    // markStartPushpin2.setLocation(lastPolylineLocations[0]);
    // markStartPushpin2.setOptions({ visible: true });
  }
};

const handleMap1Click = mapMouseEvent => {
  const { target: map1, latlng } = mapMouseEvent;
  const outlines1LayerGroup = getOutlinesLayerGroup(map1);
  const { _mfMetadata: metadata1 } = outlines1LayerGroup;
  const { outlineList: outline1List, map2 } = metadata1;

  const outlines2LayerGroup = getOutlinesLayerGroup(map2);
  const { _mfMetadata: metadata2 } = outlines2LayerGroup;

  if (outline1List.length === 0) {
    outline1List.addOutline();
    metadata2.outlineCenter = map2.getCenter();
  }

  const currentOutline = outline1List.getLastOutline();
  currentOutline.addPoint(latlng);

  updateMap2Outlines(map1, map2, latlng);
};

const handleMap1MoveStarted = mapMouseEvent => {
  const { target: map1 } = mapMouseEvent;
  L.DomUtil.addClass(map1._container, 'mf-map-moving');
};

const handleMap1MoveEnded = mapMouseEvent => {
  const { target: map1 } = mapMouseEvent;
  L.DomUtil.removeClass(map1._container, 'mf-map-moving');
};

const PUSHPIN_WIDTH = 32;
const PUSHPIN_HEIGHT = 32;
const PUSHPIN_ANCHOR_X = PUSHPIN_WIDTH * 0.5;
const PUSHPIN_ANCHOR_Y = PUSHPIN_HEIGHT - 1;

const initOutlinesLayerGroup = map => {
  // LayerGroup to hold outlines
  const outlinesLayerGroup = L.layerGroup(undefined, { interactive: false });

  const myIcon = L.divIcon({
    className: 'mf-marker',
    html: mapMarkerSvgString,
    iconSize: L.point(PUSHPIN_WIDTH, PUSHPIN_HEIGHT),
    iconAnchor: L.point(PUSHPIN_ANCHOR_X, PUSHPIN_ANCHOR_Y),
  });

  // init the 'start' map marker
  const markStartPushpin = L.marker(map.getCenter(), {
    icon: myIcon,
    keyboard: false,
    interactive: false,
    alt: '',
  });

  // init _mfMetadata
  outlinesLayerGroup._mfMetadata = {
    outlineList: new OutlineList(outlinesLayerGroup),
    outlineBounds: null,
    markStartPushpin,
  };

  // ??? does 'map._mfMetadata' really need 'markStartPushpin'?
  Object.assign(map._mfMetadata, { outlinesLayerGroup, markStartPushpin });

  // insert the layer
  outlinesLayerGroup.addTo(map);

  return outlinesLayerGroup;
};

// reverse the actions of initOutlinesLayerGroup()
const removeOutlinesLayerGroup = map => {
  const outlinesLayerGroup = getOutlinesLayerGroup(map);

  if (outlinesLayerGroup) {
    // get attached objects
    const { outlineList, markStartPushpin } = outlinesLayerGroup._mfMetadata;

    // clear outlineList
    outlineList?.clear();

    // disconnect pushPin
    markStartPushpin?.remove();

    // remove LayerGroup from map
    outlinesLayerGroup.clearLayers();
    outlinesLayerGroup.remove();

    // disconnect from map metadata
    Object.assign(map._mfMetadata, {
      outlinesLayerGroup: null,
      markStartPushpin: null,
    });

    // clear my metadata
    outlinesLayerGroup._mfMetadata = null;
  }
};

const handleMap1Added = ({ map }) => {
  initOutlinesLayerGroup(map);

  // css
  // suggested by: https://stackoverflow.com/questions/14106687
  L.DomUtil.addClass(map._container, 'mf-clickable');

  // general click handler on the map
  map.on('click', handleMap1Click);

  // map moving begin/end
  map.on('movestart', handleMap1MoveStarted);
  map.on('moveend', handleMap1MoveEnded);
  map.on('zoomend', handleMap1MoveEnded);
  map.on('viewreset', handleMap1MoveEnded);
};

const handleMap2MoveStarted = mapMouseEvent => {
  const { target: map2 } = mapMouseEvent;
  const outlines2LayerGroup = getOutlinesLayerGroup(map2);
  const { outlineList: outline2List } = outlines2LayerGroup._mfMetadata;

  if (outline2List.isAnySegments()) {
    const projectedPolylineList = outline2List.map(outline => outline.getProjectedPointsSeq(map2));
    store.dispatch(assignOverlayOutlines(projectedPolylineList));
  }

  // TODO: kill clearOutlinesLayerGroup() and have outlineList.clear() do that
  // separation of concerns: OutlineList should do all maintenance
  // of add/remove Layers within a LayerGroup
  clearOutlinesLayerGroup(map2);
  outline2List.clear(); // not needed? subsequent outline2List.init() implicitly erases

  map2._mfMetadata.isMapMoving = true;
};

const handleMap2MoveEnded = mapMouseEvent => {
  const { target: map2 } = mapMouseEvent;
  const { _mfMetadata: metadata2 } = map2;
  const { isMapMoving } = metadata2;
  if (isMapMoving) {
    store.dispatch(removeOverlayOutlines());

    const outlines2LayerGroup = getOutlinesLayerGroup(map2);
    const { map1, outlineList: outline2List } = outlines2LayerGroup._mfMetadata;
    const outlines1LayerGroup = getOutlinesLayerGroup(map1);
    const { outlineList: outline1List } = outlines1LayerGroup._mfMetadata;

    if (outline1List?.isAnyContent()) {
      const pointLists1 = outline1List.getPointLists();
      const outlines1Center = outlines1LayerGroup._mfMetadata.outlineBounds.getCenter();
      const outlines2Center = map2.getCenter();
      const polylineList2 = genMap2Polylines(pointLists1, outlines1Center, outlines2Center);
      outline2List.init(polylineList2);
    }
  }

  Object.assign(map2._mfMetadata, {
    isMapMoving: false,
    outlineCenter: map2.getCenter(),
  });
};

const handleMap2Added = ({ map }) => {
  initOutlinesLayerGroup(map);

  // map moving begin/end
  map.on('movestart', handleMap2MoveStarted);
  map.on('moveend', handleMap2MoveEnded);
  map.on('zoomend', handleMap2MoveEnded);
  map.on('viewreset', handleMap2MoveEnded);
};

const handleMap2Removed = ({ map }) => {
  // unassign click handlers
  map.off('movestart', handleMap2MoveStarted);
  map.off('moveend', handleMap2MoveEnded);
  map.off('zoomend', handleMap2MoveEnded);
  map.off('viewreset', handleMap2MoveEnded);

  removeOutlinesLayerGroup(map);
};

const handleMap1Remove = ({ map }) => {
  // remove css class
  L.DomUtil.removeClass(map._container, 'mf-clickable');

  // unassign click handlers
  map.off('click', handleMap1Click);
  map.off('movestart', handleMap1MoveStarted);
  map.off('moveend', handleMap1MoveEnded);
  map.off('zoomend', handleMap1MoveEnded);
  map.off('viewreset', handleMap1MoveEnded);

  removeOutlinesLayerGroup(map);
};

export function OutlinesPage() {
  // TODO: "Really" should keep the current outline and overlay-polyline as state.
  // But then I'd have to fold several map2 functions into this main function:
  //  handleMap2Added,handleMap2Remove(d), handleMap2MoveStarted, handleMap2MoveEnded.
  // Instead, I'm keeping the overlay-polyline as redux state.

  const map1 = useMap({
    mapNum: 1,
    parentDivId: OUTLINES_MAP1_CONTAINER_ID,
    onAdded: handleMap1Added,
    onRemove: handleMap1Remove,
  });
  const map2 = useMap({
    mapNum: 2,
    parentDivId: OUTLINES_MAP2_CONTAINER_ID,
    onAdded: handleMap2Added,
    onRemove: handleMap2Removed,
  });

  // map1 or map2 may still be undefined; if so, getOutlinesLayerGroup() returns 'undefined'
  const outlines1LayerGroup = getOutlinesLayerGroup(map1);
  const outlines2LayerGroup = getOutlinesLayerGroup(map2);

  // const outline1List = outlines1LayerGroup?._mfMetadata?.outlineList;
  // const isAnySegments = outline1List?.isAnySegments() || false;

  useEffect(() => {
    let isEffectCompleted = false;
    if (outlines1LayerGroup && outlines2LayerGroup) {
      // cross-pollinate map references
      outlines1LayerGroup._mfMetadata.map2 = map2;
      outlines2LayerGroup._mfMetadata.map1 = map1;

      // if requested, load a saved map...
      const urlSearchParams = new URLSearchParams(location.search);
      const savedMapNumStr = urlSearchParams.get('show');
      const savedMapNum = parseInt(savedMapNumStr);
      const initOutlineArray = [];
      if (Number.isInteger(savedMapNum)) {
        const fetchPromise = fetchPhpContent('mover_get.php', `id=${savedMapNum}`);
        fetchPromise.then(outlinesXml => {
          if (isEffectCompleted) {
            return;
          }

          if (!outlinesXml) {
            alert('Unfortunately, an error occurred attempting to retrieve the saved map.');
            return;
          }

          const { documentElement } = outlinesXml;
          const polylineElementCollection = documentElement.getElementsByTagName('polyline');

          // instantiate Outline objects based on XML data
          for (let idx = 0; idx < polylineElementCollection.length; idx++) {
            const outline = genOutlineFromXmlElement(polylineElementCollection[idx]);
            if (outline.getNumPoints() > 1) {
              initOutlineArray.push(outline);
            }
          }

          if (!initOutlineArray.length) {
            alert('There is no data in this saved map');
            return;
          }

          // init map outlines to the new data
          const { outlineList } = outlines1LayerGroup._mfMetadata;
          outlineList.init(initOutlineArray);
          outlineList.addOutline(); // start a new, empty outline
          updateMap2Outlines(map1, map2, null);

          // now get map settings...
          const mapLookup = [map1, map2];
          const mapElementCollection = documentElement.getElementsByTagName('map');
          for (let idx = 0; idx < mapElementCollection.length; idx++) {
            const mapSettings = getMapSettingsFromXmlElement(mapElementCollection[idx]);
            const { num, lat, lng, zoom, type } = mapSettings;
            const mapArrayIndex = parseInt(num) - 1;
            const thisMap = mapLookup[mapArrayIndex];
            const latFloat = parseFloat(lat);
            const lngFloat = parseFloat(lng);
            const zoomFloat = parseFloat(zoom);
            if (thisMap) {
              if (
                Number.isFinite(latFloat) &&
                Number.isFinite(lngFloat) &&
                Number.isFinite(zoomFloat)
              ) {
                const mapCenter = L.latLng(latFloat, lngFloat);
                thisMap.setView(mapCenter, zoomFloat);
              }

              const requestedTileLayerName = MfTiles.getTileLayerNameFromDbMapType(type);
              thisMap._mfMetadata?.mfTiles?.setCurrentTileLayer(requestedTileLayerName);
            }
          }
        });
      }
    }

    return () => {
      isEffectCompleted = true;
    };
  }, [map1, map2, outlines1LayerGroup, outlines2LayerGroup]);

  const handleReposition = useCallback(() => {
    map1?.invalidateSize(true);
    map2?.invalidateSize(true);
  }, [map1, map2]);

  return (
    <PageContext.Provider value="Compare Outlines">
      <OutlinesContext.Provider value={{ map1, map2 }}>
        <MfCanonical pathname="outlines" />
        <BannerLayout>
          <OutlinesBannerControls />
          <OutlinesBannerOps />
        </BannerLayout>
        <BodyLayout>
          <SideBySide repositionCallback={handleReposition}>
            <>
              <SearchBox map={map1} />
              <div id={OUTLINES_MAP1_CONTAINER_ID} className="mf-map-active outlines-pane" />
            </>
            <>
              <SearchBox map={map2} />
              <OutlinesMap2DraggingOverlay />
              <div id={OUTLINES_MAP2_CONTAINER_ID} className="mf-map-active outlines-pane" />
            </>
          </SideBySide>
        </BodyLayout>
        <OutlinesInfoDialog />
      </OutlinesContext.Provider>
    </PageContext.Provider>
  );
}
