import { useContext, useCallback } from 'react';
import store from './store';
import { globalUpdateDialogId } from './globalSlice';
import { assignIsSaveEnabled } from './outlinesSlice';
import { Button } from '@blueprintjs/core';
import {
  OutlinesContext,
  OUTLINES_INSTRUCTIONS_DIALOG_ID,
  getOutlinesLayerGroup,
} from './outlinesPageHelpers';

const updateDialogIdAction = globalUpdateDialogId(OUTLINES_INSTRUCTIONS_DIALOG_ID);
const handleShowInfoDialogClick = () => {
  store.dispatch(updateDialogIdAction);
};

function BannerControlButton(buttonProps) {
  // OMIT: intent={Classes.INTENT_PRIMARY} -- removes button border
  // to make fully non-focusable:
  // https://stackoverflow.com/questions/9152096
  return <Button size="small" tabIndex="-1" active={false} {...buttonProps} />;
}

const deleteLastOutlineListPointFromMap = map => {
  const outlinesLayerGroup = getOutlinesLayerGroup(map);
  const { outlineList, markStartPushpin } = outlinesLayerGroup._mfMetadata;
  const lastOutline = outlineList.getLastOutline();
  if (lastOutline) {
    const numOutlinePoints = lastOutline.getNumPoints() || 0;
    lastOutline.removeLastPoint();
    if (numOutlinePoints < 2) {
      markStartPushpin?.remove();
      outlineList.removeLastOutline();
    }

    const isSaveEnabled = outlineList.isAnySegments();
    store.dispatch(assignIsSaveEnabled(isSaveEnabled));
  }
};

const clearOutlinesFromMap = map => {
  const outlinesLayerGroup = getOutlinesLayerGroup(map);
  const { outlineList, markStartPushpin } = outlinesLayerGroup._mfMetadata;

  outlineList.removeAllContent();
  store.dispatch(assignIsSaveEnabled(false));

  markStartPushpin?.remove();

  // outlinesLayerGroup.clearLayers();
};

export function OutlinesBannerControls() {
  const { map1, map2 } = useContext(OutlinesContext);

  const handleFinishOutlineClick = useCallback(
    blueprintMouseEvent => {
      const { currentTarget } = blueprintMouseEvent;
      const { id } = currentTarget;
      const outlines1LayerGroup = getOutlinesLayerGroup(map1);
      const { outlineList: outline1List, markStartPushpin: markStartPushpin1 } =
        outlines1LayerGroup._mfMetadata;
      const lastOutline1 = outline1List.getLastOutline();
      const numOutlinePoints = lastOutline1?.getNumPoints() || 0;
      if (!lastOutline1 || numOutlinePoints < 2) {
        return;
      }
      markStartPushpin1.remove();

      // handle close-outline case
      if (id === 'outline-button-close' && numOutlinePoints > 2) {
        const firstLocation1 = lastOutline1.pointList[0];
        lastOutline1.addPoint(firstLocation1);
        const outlines2LayerGroup = getOutlinesLayerGroup(map2);
        const { _mfMetadata: metadata2 } = outlines2LayerGroup;
        const { outlineList: outline2List } = metadata2;
        const lastOutline2 = outline2List.getLastOutline();
        const firstLocation2 = lastOutline2.pointList[0];
        lastOutline2.addPoint(firstLocation2);
      }

      // add the 'next' outline, which starts out empty
      outline1List.addOutline();
    },
    [map1, map2]
  );
  const handleDeleteClick = useCallback(() => {
    deleteLastOutlineListPointFromMap(map1);
    deleteLastOutlineListPointFromMap(map2);
  }, [map1, map2]);
  const handleClearClick = useCallback(() => {
    clearOutlinesFromMap(map1);
    clearOutlinesFromMap(map2);
  }, [map1, map2]);

  return (
    <div className="mf-banner-area mf-banner-controls">
      <BannerControlButton
        text="?"
        title="Instructions for this page"
        onClick={handleShowInfoDialogClick}
      />
      <BannerControlButton
        text="Close"
        title="Close this outline and start another"
        id="outline-button-close"
        onClick={handleFinishOutlineClick}
      />
      <BannerControlButton
        text="End"
        title="Finish this outline and start another"
        id="outline-button-end"
        onClick={handleFinishOutlineClick}
      />
      <BannerControlButton
        text="Delete"
        title="Delete last point in outline"
        onClick={handleDeleteClick}
      />
      <BannerControlButton
        text="Clear"
        title="Clear all points and outlines"
        onClick={handleClearClick}
      />
    </div>
  );
}
