import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getOverlayOutlines } from './outlinesSlice';
import { SideBySideChildContext } from './SideBySideChild';

export function OutlinesMap2DraggingOverlay() {
  const dimensions = useContext(SideBySideChildContext);
  const overlayOutlines = useSelector(getOverlayOutlines);
  const isAnyOutlines =
    Boolean(overlayOutlines) && Array.isArray(overlayOutlines) && overlayOutlines.length > 0;

  let resultSvg = null;
  if (isAnyOutlines) {
    const polylineList = overlayOutlines.map(pointList => {
      let resultPolyline = null;
      if (pointList.length > 1) {
        const pairList = pointList.map(pair => `${pair.x},${pair.y}`);
        const pointsStr = pairList.join(' ');
        resultPolyline = <polyline key={pointsStr} points={pointsStr} />;
      }
      return resultPolyline;
    });

    if (dimensions) {
      resultSvg = (
        <svg
          className="mf-outlines-dragging-overlay"
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          {polylineList}
        </svg>
      );
    }
  }

  return resultSvg;
}
