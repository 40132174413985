import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Outlet,
  useLocation,
  useRouteError,
  isRouteErrorResponse,
} from 'react-router-dom';
import { BlueprintProvider } from '@blueprintjs/core';
import store from './store';
import { OutlinesPage } from './OutlinesPage';
import { WallToWallPage } from './WallToWallPage';
import { IsollPage } from './IsollPage';
import { MfName } from './MfName';

import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

export function PageLayout() {
  // TODO: is this the best implementation?
  return <Outlet />;
}

const indentHref = { marginLeft: 11 };

function RouterError() {
  const routerLocation = useLocation();
  const routeError = useRouteError();
  const isErrorResponse = isRouteErrorResponse(routeError);
  const is404 = isErrorResponse && routeError.status === 404;
  return (
    <div className="mf-error-response">
      {is404 && (
        <>
          <h1>
            Unknown <MfName /> feature
          </h1>
          <div>
            <p>
              You have requested the following <MfName /> URL:
            </p>
            <p className="mf-italic" style={indentHref}>
              {location.href}
            </p>
            <p>
              However, <MfName /> does not have a feature &quot;
              <span className="mf-bold">{routerLocation.pathname.slice(1)}</span> &quot;.
            </p>
            <p>Please try a different URL.</p>
          </div>
        </>
      )}
      {!isErrorResponse && (
        <>
          <h1>Unknown MAPFrappe &quot;routing&quot; error</h1>
          <div>Error message: {routeError?.message || '(missing)'}</div>
        </>
      )}
    </div>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<PageLayout />} errorElement={<RouterError />}>
      <Route path="outlines?" element={<OutlinesPage />} />
      <Route path="w2w" element={<WallToWallPage />} />
      <Route path="isoll" element={<IsollPage />} />
    </Route>
  )
);

const container = document.getElementById('mf-outer');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BlueprintProvider>
      <RouterProvider router={router} />
    </BlueprintProvider>
  </Provider>
);
