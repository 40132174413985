import { useSelector } from 'react-redux';
import { Dialog, DialogBody } from '@blueprintjs/core';
import store from './store';
import classNames from 'classnames';
import { getDialogId, globalHideDialog } from './globalSlice';

const hideDialogAction = globalHideDialog();
const handleCloseInfoDialog = () => {
  store.dispatch(hideDialogAction);
};

function MfDialog({ dialogId, children, className, ...spreadProps }) {
  const storeDialogId = useSelector(getDialogId);
  const mergedClassName = classNames('mf-dialog', className);
  delete spreadProps.onClose;

  return (
    <Dialog
      isOpen={storeDialogId === dialogId}
      className={mergedClassName}
      onClose={handleCloseInfoDialog}
      {...spreadProps}
    >
      <DialogBody className="mf-dialog-body">{children}</DialogBody>
    </Dialog>
  );
}

export default MfDialog;
