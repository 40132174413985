import { useEffect } from 'react';

function MfCanonical({ pathname }) {
  const trimmedPathname = pathname.replace('/', '');
  const canonicalURL = `${location.origin}/${trimmedPathname}${location.search}`;

  useEffect(() => {
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalURL);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalURL);
      document.head.appendChild(canonicalLink);
    }
  }, [canonicalURL]);

  return null;
}

export default MfCanonical;
