import MfDialog from './MfDialog';
import MfInfoSection from './MfInfoSection';
import MfNameInText from './MfNameInText';
import MfLinkInText from './MfLinkInText';

// image imports for Parcel...
import igsayPng from './igsay.png';
import timestampString from 'bundle-text:../static/timestamp.txt'; // eslint-disable-line import/no-unresolved
const versionString = `v${timestampString}`;

function SiteInfoDialog({ dialogId, children }) {
  return (
    <MfDialog
      dialogId={dialogId}
      className="mf-site-info-dialog"
      icon="info-sign"
      title="About MAPfrappe"
    >
      {children}

      <MfInfoSection title="Usage Quotas">
        <p>
          <MfNameInText /> doesn&rsquo;t directly implement usage quotas.
        </p>
        <p>
          However, <MfNameInText /> relies on a few open-source mapping services, and these services
          have quotas. If <MfNameInText /> users collectively exceed some of these quotas, then some
          services may be unavailable temporarily.
        </p>
        <p>
          In particular, <MfNameInText /> uses the following third-party services:
          <ul>
            <li>
              <b>Map Tiles – Streets/Features.</b> This site uses OpenStreetMap map tiles for the
              &ldquo;Streets&rdquo; tiles. OpenStreetMap does not define a specific limit, but may
              halt services to any site with an excessive usage level.{' '}
              <a href="https://operations.osmfoundation.org/policies/tiles/">{'[Link]'}</a>
            </li>
            <li>
              <b>Map Tiles – Satellite.</b> <MfNameInText /> uses satellite imagery from
              ESRI&rsquo;s &ldquo;WorldImagery&rdquo; service. ESRI has historically provided free
              access to this service, but might halt that at any time.
            </li>
            <li>
              <b>{'Address and Location Lookup (Geocoding).'}</b> The <MfNameInText /> search box
              uses geocoding services from{' '}
              <a href="https://geocode.maps.co/">{'geocode.maps.co'}</a>, with limits of 1 request
              per second and 5000 requests per day. These limits apply to all <MfNameInText /> users
              worldwide, not just your session. When any limit is exceeded, the provider may suspend{' '}
              <MfNameInText />
              &rsquo;s access for a day.
            </li>
          </ul>
        </p>
      </MfInfoSection>

      <MfInfoSection title="What is “frappe”?">
        <p>
          <i>Frapp&eacute;</i> is the French word for &ldquo;shaken.&rdquo; It&rsquo;s also a
          blended coffee beverage invented in Greece. Wikipedia discusses several uses of the word{' '}
          <MfLinkInText href="http://en.wikipedia.org/wiki/Frappe">here</MfLinkInText>.
        </p>
        <p>
          People in the Boston area also refer to a milkshake as a &ldquo;frappe.&rdquo; They
          pronounce it &ldquo;frap,&rdquo; to rhyme with &ldquo;map.&rdquo; I invite visitors to
          pronounce &ldquo;
          <MfNameInText />
          &rdquo; any way they like, but I pronounce it like the Boston folk, as
          &ldquo;map-frap.&rdquo;
        </p>
      </MfInfoSection>

      <MfInfoSection title="History of this site">
        <p>
          <strong>2006.</strong> I created the earliest version of this site to help me explore
          various geographic relationships. Since then, I’ve regularly updated the site for changes
          to Google Maps and various web browsers.
        </p>
        <p>
          <strong>2014.</strong> I updated the site with a “responsive” user interface — for mobile
          browsers and improved user experience.
        </p>
        <p>
          <strong>2018.</strong> Google introduced fees for its mapping services, where previously
          access had been free. I restricted access to <MfNameInText />. I reduced support for small
          devices such as phones.
        </p>
        <p>
          <strong>2025.</strong> I completely re-implemented the site using free, open-source
          mapping services. Some previous features are still a work-in-progress.
        </p>
        <p>&mdash; Kelvin Thompson</p>
      </MfInfoSection>

      <MfInfoSection title="Contact">
        <p>
          If you have some comments about this site, I would love to hear from you at the following
          email address:
        </p>
        <p>
          <img src={igsayPng} alt="see image for email address" />
        </p>
        <p>
          But I may change this address someday if it gets too much spam. Check back here for the
          latest address each time you want to write.
        </p>
      </MfInfoSection>

      <p className="mf-copyright-line">
        Copyright &copy; 2025 by Kelvin Thompson. All Rights Reserved.
        <br />
        <span className="mf-version">{versionString}</span>
      </p>
    </MfDialog>
  );
}

export default SiteInfoDialog;
