import L from 'leaflet';
import Outline from './Outline';
import { removeAllPolylinesFromLayerGroup } from './outlinesPageHelpers';

class OutlineList extends Array {
  constructor(layerGroup, outlineArray) {
    // NOTE: I tried moving invocation of super() to here, but "super(undefined)"
    // makes a one-element array, which is different than "super()"
    const isOutlineListArray = Array.isArray(outlineArray);
    if (isOutlineListArray) {
      // !!NOTE: 9-Feb-2025 this has never been used or tested!
      super(outlineArray);
      outlineArray.forEach(outline => {
        const outlineLayer = outline.getLayer();
        layerGroup.addLayer(outlineLayer);
      });
    } else {
      super();
    }

    this.layerGroup = layerGroup;
  }

  init(initParam) {
    // remove enclosed Leaflet layers
    removeAllPolylinesFromLayerGroup(this.layerGroup);

    // force the array to empty
    this.splice(0, Infinity);

    // initialize as needed
    if (Array.isArray(initParam)) {
      initParam.forEach(outlineInitItem => this.addOutline(outlineInitItem));
    } else {
      console.error('Bogus OutlineList init()', typeof initParam);
    }
  }

  clear() {
    // remove enclosed Leaflet layers
    removeAllPolylinesFromLayerGroup(this.layerGroup);

    // force the array to empty
    this.splice(0, Infinity);
  }

  addOutline(initContent) {
    let derivedNewOutline;
    if (typeof initContent === 'undefined') {
      // We get here when the user has started a new, empty outline after
      // finishing an earlier outline
      derivedNewOutline = new Outline();
    } else if (Outline.isValidInitContent(initContent)) {
      derivedNewOutline = new Outline(initContent);
    } else {
      console.error('Bogus OutlineList addOutline()', typeof initContent);
    }

    if (derivedNewOutline) {
      // ??? What does it mean to have undefined 'initContent', so there's
      // no layer added here? Will we ever add content to this layer and
      // get it into the map? Maybe this case never comes up?
      // When/how will the empty Outline created above be added to 'layerGroup'?
      this.layerGroup.addLayer(derivedNewOutline.getLayer());
    }

    return derivedNewOutline && this.push(derivedNewOutline);
  }

  getLastOutline() {
    return this[this.length - 1];
  }

  removeLastOutline() {
    const lastOutline = this.pop();
    if (lastOutline) {
      this.layerGroup.removeLayer(lastOutline.getLayer());
    }
    return lastOutline;
  }

  removeAllContent() {
    while (this.length > 0) {
      this.removeLastOutline();
    }
  }

  isAnyContent() {
    return this.length > 0 && Boolean(this.find(outline => outline.getNumPoints() > 0));
  }

  isAnySegments() {
    return this.length > 0 && Boolean(this.find(outline => outline.getNumPoints() > 1));
  }

  getPointLists() {
    return this.map(outline => outline.getPointList());
  }

  getFirstLocationOfLastOutline() {
    const lastOutline = this.getLastOutline();
    return lastOutline?.pointList[0];
  }

  // getClonedPointLists() { -- not used yet
  //   return this.map(outline => outline.getClonedPointList());
  // }

  getBounds() {
    if (this.length === 0 || this[0].getNumPoints() === 0) {
      return undefined;
    }

    const zerothPoint = this[0].getPointList()[0];
    const resultBounds = L.latLngBounds([zerothPoint]);
    this.forEach(outline => resultBounds.extend(outline.getBounds()));
    return resultBounds;
  }

  genXml() {
    const polylinesBodyXml = this.reduce((accum, outline) => accum + outline.genXml(), '');
    return `<polylines>\n${polylinesBodyXml}</polylines>\n`;
  }
}

export default OutlineList;
