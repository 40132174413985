import { configureStore } from '@reduxjs/toolkit';

import globalReducer from './globalSlice';
import mapsReducer from './mapsSlice';
import outlinesReducer from './outlinesSlice';

const store = configureStore({
  reducer: {
    global: globalReducer,
    maps: mapsReducer,
    outlines: outlinesReducer,
  },
});

export default store;
