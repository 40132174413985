import { createSlice } from '@reduxjs/toolkit';

const EMPTY_OVERLAY_OUTLINES = [];

const initialState = {
  // outlines: [], -- 23-Jan-2025: not in use; OutlinesPage passes outlines directly between maps
  overlayOutlines: EMPTY_OVERLAY_OUTLINES,
  isSaveEnabled: false,
};

// const getLastOutline = outlines => outlines[outlines.length - 1];

const outlinesSlice = createSlice({
  name: 'outlines',
  initialState,
  reducers: {
    // outlineAddPoint(state, action) {
    //   const { outlines } = state;
    //   let lastOutline = getLastOutline(outlines);
    //   if (!lastOutline) {
    //     lastOutline = [];
    //     state.outlines = [lastOutline];
    //   }
    //   // add the point to the outline
    //   const { point } = action.payload;
    //   const updatedLastOutline = [...lastOutline];
    //   updatedLastOutline.push(point);
    //   // update the list of outlines
    //   const updatedOutlines = [...outlines];
    //   updatedOutlines.splice(updatedOutlines.length - 1, 1, updatedLastOutline);
    //   return {
    //     ...state,
    //     outlines: updatedOutlines,
    //   };
    // },
    assignOverlayOutlines(state, action) {
      const outlines = action.payload;
      return {
        ...state,
        overlayOutlines: [...outlines],
      };
    },
    removeOverlayOutlines(state) {
      return {
        ...state,
        overlayOutlines: EMPTY_OVERLAY_OUTLINES,
      };
    },
    assignIsSaveEnabled(state, action) {
      const isSaveEnabled = action.payload;
      return {
        ...state,
        isSaveEnabled,
      };
    },
  },
});

export function getOverlayOutlines(state) {
  const { overlayOutlines } = state.outlines;
  return overlayOutlines;
}

export function getIsSaveEnabled(state) {
  const { isSaveEnabled } = state.outlines;
  return isSaveEnabled;
}

// export actions
export const { assignOverlayOutlines, removeOverlayOutlines, assignIsSaveEnabled } =
  outlinesSlice.actions;

export default outlinesSlice.reducer;
